import React, { FunctionComponent, useState } from 'react';
import useForm from 'react-hook-form';
import classNames from 'classnames';
import firebaseApp, { firebase } from '../hooks/use-firebase';

type SubmitMessage = {
  message: string;
  visible: boolean;
  error: boolean;
};

type Fields = 'name' | 'email' | 'subject' | 'body';

const useSubmitMessage = (): [SubmitMessage, (newSubmitMessage: SubmitMessage, timeOut?: number) => void, () => void] => {
  const emptySubmitMessage = { message: '//', visible: false, error: false };
  const [submitMessage, setSubmitMessage] = useState(emptySubmitMessage);
  const clearSubmitMessage = () => {
    setSubmitMessage(emptySubmitMessage);
  };
  const setAndHideSubmitMessage = (newSubmitMessage: SubmitMessage, timeOut = 5000) => {
    setSubmitMessage(newSubmitMessage);
    setTimeout(clearSubmitMessage, timeOut);
  };
  return [submitMessage, setAndHideSubmitMessage, clearSubmitMessage];
};

const IndexPage: FunctionComponent = () => {
  const { register, handleSubmit, errors, reset } = useForm();
  const [submitMessage, setSubmitMessage] = useSubmitMessage();
  const [submitBnt, setSubmitBtn] = useState('Send');
  const db = firebaseApp.firestore();

  const onSubmit = (data: Record<Fields, string>) => {
    const emailData = {
      createdOn: firebase.firestore.Timestamp.now(),
      to: 'korneel@pienter.tech',
      replyTo: data.email,
      message: {
        subject: `Contact request: ${data.subject}`,
        html: `<h1>${data.name} wrote:</h1> <p>${data.body}</p>`,
      },
    };
    setSubmitBtn('Sending...');
    db.collection('contactFormResponses')
      .add(emailData)
      .then(() => {
        reset();
        setSubmitMessage({
          message: 'Your message is sent, I will get back to you as soon as possible',
          visible: true,
          error: false,
        });
        setSubmitBtn('Send another');
      })
      .catch(() => {
        setSubmitMessage({
          message: "Your message is not sent, I'm not sure why, please try again, or email me on korneel@pienter.tech.",
          visible: true,
          error: true,
        });
        setSubmitBtn('Try again');
      });
  };

  return (
    <form id="contact-form" className="c-contact-form" onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="field-name" className="c-contact-form__label">
        Name:
        <input
          id="field-name"
          name="name"
          className="c-contact-form__field"
          type="text"
          ref={register({ required: 'Enter your name here.' })}
        />
      </label>
      <p className="c-contact-form__error">{errors.name && errors.name.message}</p>
      <label htmlFor="field-email" className="c-contact-form__label">
        Email Address:
        <input
          id="field-email"
          name="email"
          className="c-contact-form__field"
          type="text"
          ref={register({
            required: 'Enter your email address here.',
            pattern: {
              value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: 'Please enter a valid email address.',
            },
          })}
        />
      </label>
      <p className="c-contact-form__error">{errors.email && errors.email.message}</p>
      <label htmlFor="field-subject" className="c-contact-form__label">
        Subject:
        <input
          id="field-subject"
          name="subject"
          className="c-contact-form__field"
          type="text"
          ref={register({ required: 'Enter the subject of your message here.' })}
        />
      </label>
      <p className="c-contact-form__error">{errors.name && errors.name.message}</p>
      <label htmlFor="field-body" className="c-contact-form__label">
        Message:
        <textarea
          id="field-body"
          name="body"
          className="c-contact-form__field"
          ref={register({
            required: 'Enter your question/comment here.',
          })}
        />
      </label>
      <p className="c-contact-form__error">{errors.body && errors.body.message}</p>
      <div className="c-contact-form__submit-container">
        <button id="field-submit" className="c-contact-form__submit-btn" type="submit">
          {submitBnt}
        </button>
        <p
          className={classNames({
            'c-contact-form__submit-message': true,
            'c-contact-form__submit-message--visible': submitMessage.visible,
            'c-contact-form__submit-message--error': submitMessage.error,
          })}
        >
          {submitMessage.message}
        </p>
      </div>
    </form>
  );
};

export default IndexPage;
